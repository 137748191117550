<template>

  <div class="container-fluid">
    <div class="row">
      <showHeader class="col-12 mt-3"
                  :icon="'user-cog'"
                  :title="item.name"
                  :stat="item.stat"
                  :statText="item.stat ? $t('employees.show.active') : $t('employees.show.inactive')"
                  :subData="[
                    {title: $t('employees.show.Title'), value: item.job_title},
                    {title: $t('employees.show.Salary'), value: $moneyFormat(item.salary + ' ' + $option.currency)},
                  ]"
                  :buttons="[
                    {title: $t('employees.show.modulation'), icon: 'edit', link: path+'/'+$route.params.id+'/edit', role: $user.admin || $user.role.empolyees_edit},
                  ]"
      />
      <div class="col-12">
        <showFile />
      </div>
      <employeesSalaries class="col-12" v-if="$site.employeesSalaries_allow && ($user.admin || $user.role.employees_salaries_show)"/>
      <employeesIncentives class="col-12" v-if="$site.employeesIncentives_allow && ($user.admin || $user.role.employees_incentives_show)"/>
      <employeesDeductions class="col-12" v-if="$site.employeesDeductions_allow && ($user.admin || $user.role.employees_deductions_show)"/>
      <employeesWithdrawals class="col-12" v-if="$site.employeesWithdrawals_allow && ($user.admin || $user.role.employees_withdrawals_show)"/>
    </div>
  </div>

</template>
<script>

  import axios from 'axios'

  import showHeader from '../elements/show/showHeader.vue'
  import showBoxs from '../elements/show/showBoxs.vue'
  import employeesSalaries from './components/employeesSalaries.vue'
  import employeesIncentives from './components/employeesIncentives.vue'
  import employeesDeductions from './components/employeesDeductions.vue'
  import employeesWithdrawals from './components/employeesWithdrawals.vue'

  import showFile from '../elements/show/showFile.vue';
  export default {
    data() {
      return {
        path: '/employees',
        loaded: false,
        item: {}
      }
    },
    mounted() {
      this.getItems()
    },
    methods: {
      getItems() {
        var item = this.$database.employees.find(el => el.id == this.$route.params.id)
        if (item) {this.item = item}
        axios.get(this.$linkGnirator(this.path + '/' + this.$route.params.id)).then(response => {
          this.item = response.data
          this.loaded = true
        })
      },
      sureDelete(id) {
        this.delete_id = id
        this.$parent.model = 1
      },
      deleteItem() {
        axios
          .post(this.$linkGnirator('/expenses/' + this.item.expenses.data[this.delete_id].id), {
            _method: 'DELETE'
          })
          .then(response => {
            this.getItems()
            this.delete_id = null
            this.$parent.model = 0
            return response
          })
      },
      sureDelete2(id) {
        this.delete_id_2 = id
        this.$parent.model = 1
      },
      deleteItem2() {
        axios
          .post(this.$linkGnirator('/attendanceHistories/' + this.item.attendanceHistory.data[this.delete_id_2].id), {
            _method: 'DELETE'
          })
          .then(response => {
            this.getItems()
            this.delete_id_2 = null
            this.$parent.model = 0
            return response
          })
      },
      sureDelete3(id) {
        this.delete_id_3 = id
        this.$parent.model = 1
      },
      deleteItem3() {
        axios
          .post(this.$linkGnirator('/salaryHistories/' + this.item.salaryHistory.data[this.delete_id_3].id), {
            _method: 'DELETE'
          })
          .then(response => {
            this.getItems()
            this.delete_id_3 = null
            this.$parent.model = 0
            return response
          })
      }
    },
    components: {
      showHeader,
      showBoxs,
      employeesSalaries,
      employeesIncentives,
      employeesDeductions,
      employeesWithdrawals,
      showFile
    }
  }

</script>
