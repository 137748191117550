var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('components.employees.employeesDiscounts')))]),(
          _vm.$site.invoices_allow &&
          (_vm.$user.admin ||
            _vm.$user.role.invoices_add)
        )?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/employeesDeductions/create?employee_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.customersInvoices.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('employeesDeductions.SearchForAnOperation'),"emptyTableText":_vm.$t('employeesDeductions.ThereAreNoDiscounts'),"filter":{employee_id: _vm.$route.params.id},"withoutCard":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
          { column: 'code', title: _vm.$t('employeesDeductions.Discount'), type: 'mainLink', sort: true },
          { column: 'employee_id',  title:_vm.$t('employeesDeductions.functionary'),  type: 'link', to:'employee', sort: true, link: true},
          { column: 'date', title: _vm.$t('employeesDeductions.date'), type: 'text', sort: true },
          {
            column: 'description',
            title: _vm.$t('employeesDeductions.thatsAbout'),
            type: 'text',
            sort: true,
          },
          { column: 'notes', title: _vm.$t('employeesDeductions.note'), type: 'text', sort: true },
          {
            column: 'cost',
            title: _vm.$t('employeesDeductions.cost'),
            type: 'text',
            sort: true
          },

          {
            column: 'options',
            title: _vm.$t('employeesDeductions.Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$user.admin || _vm.$user.role.expenses_edit,
              },
              { name: 'download', role: true },
              {
                name: 'delete',
                role: _vm.$user.admin || _vm.$user.role.expenses_delete,
              } ],
          } ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem:
            _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading'),
        }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }