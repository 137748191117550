<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">{{$t('components.employees.employeeIncentives')}}</h4>
        <router-link
          class="btn btn-sm btn-primary"
          :to="'/employeesIncentives/create?employee_id=' + $route.params.id"
          v-if="
            $site.invoices_allow &&
            ($user.admin ||
              $user.role.invoices_add)
          "
        >
          <i class="fas fa-plus"></i> {{$t('components.customersInvoices.addition')}}
        </router-link>
      </div>
       <indexTable
          :searchText="$t('employeesIncentives.SearchForAnOperation')"
          :emptyTableText="$t('employeesIncentives.ThereAreNoIncentives')"
          :filter="{employee_id: $route.params.id}"
          :withoutCard="true"
          :emptyTableSubText="
            $t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')
          "
          :cloumns="[
            { column: 'code', title: $t('employeesIncentives.incentive'), type: 'mainLink', sort: true },
            { column: 'employee_id',  title:$t('employeesIncentives.functionary'),  type: 'link', to:'employee', sort: true, link: true},
            { column: 'date', title: $t('employeesIncentives.date'), type: 'text', sort: true },
            {
              column: 'description',
              title: $t('employeesIncentives.thatsAbout'),
              type: 'text',
              sort: true,
            },
            { column: 'notes', title: $t('employeesIncentives.note'), type: 'text', sort: true },
            {
              column: 'cost',
              title: $t('employeesIncentives.cost'),
              type: 'text',
              sort: true
            },

            {
              column: 'options',
              title: $t('employeesIncentives.Settings'),
              type: 'options',
              options: [
                { name: 'show' },
                {
                  name: 'edit',
                  role: $user.admin || $user.role.expenses_edit,
                },
                { name: 'download', role: true },
                {
                  name: 'delete',
                  role: $user.admin || $user.role.expenses_delete,
                },
              ],
            },
          ]"
          :deleteText="{
            attention: $t('allerts.Attention'),
            areYouReallySureToDeleteTheItem:
              $t('allerts.areYouReallySureToDeleteTheItem'),
            close: $t('allerts.close'),
            confirm: $t('allerts.confirm'),
            loading: $t('allerts.loading'),
          }"
        />
    </div>
  </div>
</template>
<script>
import indexTable from "../../elements/index/indexTable.vue";
export default {
  data() {
    return {
      path: "/employeesIncentives",
    };
  },
  components: {
    indexTable,
  },
};
</script>
