<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">{{$t('components.employees.staffDraws')}}</h4>
        <router-link
          class="btn btn-sm btn-primary"
          :to="'/employeesWithdrawals/create?employee_id=' + $route.params.id"
          v-if="
            $site.invoices_allow &&
            ($user.admin ||
              $user.role.invoices_add)
          "
        >
          <i class="fas fa-plus"></i> {{$t('components.customersInvoices.addition')}}
        </router-link>
      </div>
      <indexTable
          :searchText="$t('employeesWithdrawals.SearchForAnOperation')"
          :emptyTableText="$t('employeesWithdrawals.ThereAreNoWithdrawals')"
          :filter="{employee_id: $route.params.id}"
          :withoutCard="true"
          :emptyTableSubText="
            $t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')
          "
          :cloumns="[
            { column: 'code', title: $t('employeesWithdrawals.Withdraw'), type: 'mainLink', sort: true },
            { column: 'employee_id', title: $t('employeesWithdrawals.EmployeesName'), to: 'employee', type: 'link', sort: true, link: true },
            {
              column: 'safe_id',
              title: $t('employeesWithdrawals.Treasury'),
              type: 'link',
              to: 'safe',
              sort: true,
              link: true,
            },
            { column: 'date', title: $t('employeesWithdrawals.date'), type: 'text', sort: true },
            {
              column: 'cost',
              title: $t('employeesWithdrawals.cost'),
              type: 'text',
              sort: true
            },
            {
              column: 'description',
              title: $t('employeesWithdrawals.thatsAbout'),
              type: 'text',
              sort: true,
            },
            { column: 'notes', title: $t('employeesWithdrawals.note'), type: 'text', sort: true },

            {
              column: 'options',
              title: $t('employeesWithdrawals.Settings'),
              type: 'options',
              options: [
                { name: 'show' },
                {
                  name: 'edit',
                  role: $user.admin || $user.role.expenses_edit,
                },
                {
                  name: 'delete',
                  role: $user.admin || $user.role.expenses_delete,
                },
              ],
            },
          ]"
          :deleteText="{
            attention: $t('allerts.Attention'),
            areYouReallySureToDeleteTheItem:
              $t('allerts.areYouReallySureToDeleteTheItem'),
            close: $t('allerts.close'),
            confirm: $t('allerts.confirm'),
            loading: $t('allerts.loading'),
          }"
        />
    </div>
  </div>
</template>
<script>
import indexTable from "../../elements/index/indexTable.vue";
export default {
  data() {
    return {
      path: "/employeesWithdrawals",
    };
  },
  components: {
    indexTable,
  },
};
</script>
